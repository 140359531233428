<template lang="pug">
div
  Heading(v-if="isActiveShopify" label :open="limit") {{ $t('discountPane.followup.limit') }}
    template(#prependEnding)
      OmSwitch#limit.mt-3.mb-3(v-model="limit")
      .validation-failed-msg.limit-required(v-if="isLimitRequired") {{ $t(validationError.msg) }}
    template(#expandableContent)
      .limit-content
        OmInput#expires-is(v-model="expiresIn" small placeholder="e.g. 15")
        Dropdown#expires-in-units(:items="expiresInUnits" v-model="expiresInUnitValue")
  .followup-rules.mt-3
    .followup-rule-title(v-html="$t('discountPane.followup.rulesTitle')")
    div(v-html="$t('discountPane.followup.rulesText')")
    ul.follow-up-description-list
      li {{ $t('discountPane.followup.userRedeem') }}
      li {{ $t('discountPane.followup.userClose') }}
      li {{ $t('discountPane.followup.expirationEnds') }}
    div(
      v-if="$i18n.locale === 'en'"
      v-html="$t('discountPane.followup.userGuide', { link: 'https://support.optimonk.com/hc/en-us/articles/4422002586130-Discount-code-reminder' })"
    )
</template>
<script>
  import { mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import sharedMixin from '@/components/Editor/Blocks/Coupon/shared';
  import Heading from '../../Heading.vue';
  import Dropdown from '../../Dropdown/Dropdown.vue';

  export default {
    components: {
      Heading,
      Dropdown,
    },
    mixins: [itemMixin, sharedMixin],
    computed: {
      ...mapState(['validationError']),
      limit: {
        get() {
          return this.getValueOf('data.coupon.followup.limit');
        },
        set(v) {
          this.setValueOf('data.coupon.followup.limit', v);
        },
      },
      expiresIn: {
        get() {
          return this.getValueOf('data.coupon.followup.expiresIn');
        },
        set(value) {
          this.setValueOf('data.coupon.followup.expiresIn', value);
        },
      },
      expiresInUnitValue: {
        get() {
          return this.getValueOf('data.coupon.followup.expiresInUnit');
        },
        set(value) {
          this.setValueOf('data.coupon.followup.expiresInUnit', value);
        },
      },
      isLimitRequired() {
        return (
          !this.coupon.followup.limit &&
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('data.coupon.followup.limit')
        );
      },
    },
  };
</script>
<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .limit-content
    display: flex
    gap: 12px
    .input-wrapper
      flex: 0 1 35%
      margin-block: auto
    .ds-dropdown
      flex: 1
  .followup-rules
    border: 1px solid $om-gray-400
    border-radius: 4px
    color: $om-dark-grey-3
    font-size: 0.75rem
    padding: 12px
    line-height: 1.5
    .followup-rule-title
      color: black
      font-weight: bold
      margin-bottom: 12px
</style>
